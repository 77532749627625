import React, { useState } from 'react';
import styled from 'styled-components';

export default (props) => {
    let [email, setEmail] = useState('');

    return (
        <section>
            <h2 id='JoinTheDen'>Join the Den</h2>
            <p>Our journey is just beginning, and we couldn't be more thrilled to have you by our side. Be a part of our community to get the latest updates, exclusive sneak peeks, and behind-the-scenes glimpses.</p>
            <h4>SOCIAL MEDIA</h4>
            <p>Sign up for our newsletter and be the first to receive exclusive content and insider updates. Here's your chance to embark on our adventure before anyone else.</p>
            <form>
                <input type='email' value={email} onChange={(event) => {setEmail(event.target.value);}} />
                <button type='submit'>Sign Up For Newsletter</button>
            </form>
            <p>Step into the magical realm of Fable Fox Studio, where we weave enchanting fables into captivating games. Let the adventure begin.</p>
        </section>
    );
}