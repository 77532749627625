import React from "react";
import styled from "styled-components";

const HeroContent = styled.div`

`;

export default (props) => {
    return (
        <section>
            <HeroContent>
                {props.children}
            </HeroContent>
        </section>
    );
};