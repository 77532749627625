const fableFoxTheme = {
    colors: {
        orange: '#E6933B',
        lightBrown: '#543C1D',
        darkBrown: '#2A2213',
        lightGreen: '#9CB26C',
        darkGreen: '#637237',
        white: '#fafafa',
        black: '#333333'
    },
    breakpoints: {
        mobile: "@media only screen and (max-width: 768px)"
    },
}

export default fableFoxTheme;