import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HomePage from './pages/home';
import OurGamesPage from './pages/our-games';
import BlogPage from './pages/blog';
import NotFound from './pages/NotFound';
import Mark from './components/mark';
import { ThemeProvider } from 'styled-components';
import theme from './theme/theme';
import GlobalStyle from './theme/globalStyles';

export default () => {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <HomePage />,
            errorElement: <NotFound />
        },
        {
            path: "/our-games",
            element: <OurGamesPage />,
        },
        {
            path: "/blog",
            element: <BlogPage />,
        },
    ]);


    return (<>
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Mark nav />
            <RouterProvider router={router} />
        </ThemeProvider>
    </>);
}