import React from 'react';
import styled from 'styled-components';

const MainTitle = styled.span`
    font-size: ${props => props.nav ? '3rem' : '2rem'};
    font-family: 'Mate SC', serif;
    color: ${props => props.theme.colors.white};
`

const SubTitle = styled.span`
    font-size: ${props => props.nav ? '1rem' : '0.66rem'};
    font-family: 'Mate SC', serif;
    color: ${props => props.theme.colors.white};
`

const MarkWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width:  ${props => props.nav ? '210px' : '139px'};
`

const SubMarkWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`

export default (props) => {
    return (
        <MarkWrapper nav={props.nav}>
            <MainTitle nav={props.nav}>Fable Fox</MainTitle>
            <SubMarkWrapper>
                <SubTitle nav={props.nav}>s</SubTitle>
                <SubTitle nav={props.nav}>t</SubTitle>
                <SubTitle nav={props.nav}>u</SubTitle>
                <SubTitle nav={props.nav}>d</SubTitle>
                <SubTitle nav={props.nav}>i</SubTitle>
                <SubTitle nav={props.nav}>o</SubTitle>
            </SubMarkWrapper>
        </MarkWrapper>
    );
}