import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const NotFoundSection = styled.section`

`;

const NotFoundHeading = styled.h1`
    
`;

const NotFoundCopy = styled.p`
    
`;

export default () => {
    return (
        <NotFoundSection>
            <NotFoundHeading>Page not found</NotFoundHeading>
            <NotFoundCopy>We couldn't find the page you were looking for. <Link to="/">Try heading back to the homepage</Link> and start your journey again.</NotFoundCopy>
        </NotFoundSection>
    );
};