import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
    }
    
    html, body {
        font-size: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    body {
        background-color: ${props => props.theme.colors.darkBrown};
    }

    div#App {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    div, p {
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        color: ${props => props.theme.colors.white};
    }

    p {
        margin: 0 0 1rem;
        max-width: 768px;
    }

    a {
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        color: ${props => props.theme.colors.lightGreen};

        &:visited {
            color: ${props => props.theme.colors.darkGreen};
        }
    }

    h1 {
        font-family: 'Rowdies', sans-serif;
        font-weight: 400;
        font-size: 3rem;
        color: ${props => props.theme.colors.white};
    }

    h2 {
        font-family: 'Rowdies', sans-serif;
        font-weight: 400;
        font-size: 2.25rem;
        color: ${props => props.theme.colors.white};
    }

    h3 {
        font-family: 'Rowdies', sans-serif;
        font-weight: 400;
        font-size: 1.75rem;
        color: ${props => props.theme.colors.white};
    }

    h4 {
        font-family: 'Rowdies', sans-serif;
        font-weight: 400;
        font-size: 1.5rem;
        color: ${props => props.theme.colors.white};
    }

    h5 {
        font-family: 'Rowdies', sans-serif;
        font-weight: 400;
        font-size: 1.25rem;
        color: ${props => props.theme.colors.white};
    }

    h6 {
        font-family: 'Rowdies', sans-serif;
        font-weight: 400;
        font-size: 1rem;
        color: ${props => props.theme.colors.white};
    }

    section {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        width: 100%;
    }

    .primary-text {

    }

    .secondary-text {

    }
`;

export default GlobalStyle;