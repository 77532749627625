import React from 'react';
import Hero from '../../components/hero';
import LinkButton from '../../components/LinkButton';
import AlternatingSection from '../../components/AlternatingSection';
import MarketingCapture from '../../components/MarketingCapture';

export default () => {
    return (
        <>
            <Hero>
                <h1>Follow the Fox's Tale</h1>
                <p>Welcome to a world where imagination and creativity intertwine to create something truly magical. This is the realm of Fable Fox Studio, where we weave enchanting narratives and deliver unforgettable experiences through our games.</p>
                <LinkButton to="#JoinTheDen" primary>Learn More</LinkButton>
            </Hero>
            <AlternatingSection>
                <h2>Our Philosophy</h2>
                <p>We believe in the crafty and clever nature of the fox, a symbol of our commitment to wily and imaginative game development. Like a fox, we navigate the world of game development with agility, curiosity, and a knack for surprising delights, designing action-adventure games that pique your curiosity and tug at your heartstrings.</p>
            </AlternatingSection>
            <AlternatingSection contentOnRight>
                <h2>Our Game</h2>
                <p>In the den of Fable Fox, we're diligently crafting an action-adventure game that may be small in size but is grand in its scope and content. Our first creative venture carries our spirit - brimming with curious characters, heart-warming narratives, and a vibrant world awaiting your exploration.</p>
                <p>In our first game you expect an adventure filled to the brim with exciting quests, captivating stories, and surprising twists. Every corner of our game world is a treasure trove of engaging narratives and hidden secrets, waiting for you to uncover.</p>
            </AlternatingSection>
            <AlternatingSection>
                <h2>Our Team</h2>
                <p>Fable Fox Studio is run by individuals who embody the clever and creative spirit of the fox. We're dedicated game enthusiasts, meticulously weaving engaging narratives, designing immersive gameplay, and bringing to life endearing characters. Our mission? To craft an unforgettable gaming experience that captivates, engages, and inspires.</p>
            </AlternatingSection>
            <MarketingCapture />
        </>
    );
}