import React from 'react';
import styled from 'styled-components';

const SectionWrapper = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    ${props => props.theme.breakpoints.mobile} {
        flex-direction: column;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const ImageWrapper = styled.div`
    width: 50%;
`

export default (props) => {
    return (
        <SectionWrapper>
            {props.contentOnRight && <ImageWrapper/>}
            <ContentWrapper>
                {props.children}
            </ContentWrapper>
            {!props.contentOnRight && <ImageWrapper/>}
        </SectionWrapper>
    );
}