import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const LinkButtonWrapper = styled.div`
    color: ${props => props.primary ? props.theme.colors.black : props.theme.colors.white};
    background-color: ${props => props.primary ? props.theme.colors.orange : props.theme.colors.lightGreen};
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    flex-grow: 0;
`;

const ExternalLinkWrapper = styled.a`
    color: ${props => props.primary ? props.theme.colors.black : props.theme.colors.white};
    text-decoration: none;
    display: flex;
    padding: 0.5rem 2rem;
`;

export default (props) => {
    if (props.to.startsWith('/')) {
        return (
            <LinkButtonWrapper primary={props.primary}>
                <Link to={props.to}>{props.children}</Link>
            </LinkButtonWrapper>
        );
    } else {
        return (
            <LinkButtonWrapper primary={props.primary}>
                <ExternalLinkWrapper href={props.to}  primary={props.primary}>{props.children}</ExternalLinkWrapper>
            </LinkButtonWrapper>
        );
    }
    
};

